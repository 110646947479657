@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-size: 16px;
    font-weight: 300;
    line-height: 20.16px;
    font-style: normal;
    letter-spacing: 0.05em;
    text-align: left;
    @apply bg-[#252525] text-white;
  }

  /* nav menu */
  nav a, .sticky > ul a {
    font-weight: 400;
  }

  /* nav menu */
  nav a {
    @apply hover:text-orange-500 cursor-pointer py-2;
  }

  /* Company title */
  h1 {
    font-size: 26px;
    font-weight: 500;
    line-height: 32.78px;
    @apply pb-1 pt-4;
  }

  /* Logo */
  nav h1 {
    font-size: 27px;
    font-weight: 400;
    line-height: 33.6px;
    padding: 0;
  }

  /* Company role */
  h2 {
    font-size: 26px;
    line-height: 32.78px;
    @apply pb-8;
  }

  /* Paragraph */
  p {
    font-size: 16px;
  }

  img {
    @apply rounded-md;
  }

}

.before-after-slider__delimiter {
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.45);
}

.lets-see-paul-allens-shadow {
  box-shadow: 0px 18px 36px 18px rgba(159, 157, 157, 0.048),0px 30px 45px -30px rgba(50,50,93,0.25);

}

/* .content img, video {
  box-shadow: 0px 18px 36px 18px rgba(159, 157, 157, 0.048),0px 30px 45px -30px rgba(50,50,93,0.25);
} */

.before-after-slider__second-photo-container::before {
  @apply shadow-lg absolute bottom-0 left-0 bg-black bg-opacity-80 py-2 px-4 rounded m-4;
  content: 'Before';
}

.before-after-slider__first-photo-container::after {
  @apply shadow-lg absolute bottom-0 right-0 bg-black bg-opacity-80 py-2 px-4 rounded m-4;  
  content: 'After';
}

.tile-background {
  background: linear-gradient(180deg,
   rgba(0,0,0,0) 62%,
   rgba(0,0,0,0.00345888) 63.94%, 
   rgba(0,0,0,0.014204) 65.89%, 
   rgba(0,0,0,0.0326639) 67.83%, 
   rgba(0,0,0,0.0589645) 69.78%, 
   rgba(0,0,0,0.0927099) 71.72%, 
   rgba(0,0,0,0.132754) 73.67%, 
   rgba(0,0,0,0.177076) 75.61%, 
   rgba(0,0,0,0.222924) 77.56%, 
   rgba(0,0,0,0.267246) 79.5%, 
   rgba(0,0,0,0.30729) 81.44%, 
   rgba(0,0,0,0.341035) 83.39%, 
   rgba(0,0,0,0.367336) 85.33%, 
   rgba(0,0,0,0.385796) 87.28%, 
   rgba(0,0,0,0.396541) 89.22%, 
   rgba(0,0,0,0.4) 91.17%);
}



.mom-jank {
  width: calc(29% * .71);
  height: auto;
  top: 10%;
  left: 7%;
}

/* #portfolio-entry {
  min-height: calc(100vh - 174px);
}

@media (min-width: 378px) {
  #portfolio-entry {
    min-height: calc(100vh - 154px);
  }
}

@media (min-width: 640px) {
  #portfolio-entry {
    min-height: calc(100vh - 134px);
  }
} */
